<script setup lang="ts">
import device from '@@/bits/device'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    /**
     * Dark mode.
     */
    darkMode?: boolean | 'auto'
    /**
     * Direction
     */
    dir?: 'ltr' | 'rtl' | 'auto'
    /**
     * Value of checkbox
     */
    value?: boolean
    /**
     * Determines if checkbox is disabled
     */
    disabled?: boolean
    /**
     * Label of the checkbox
     */
    label?: string
    /**
     * Reverse checkbox and label direction
     */
    reverseDirection?: boolean
  }>(),
  {
    darkMode: 'auto',
    dir: 'auto',
    value: false,
    disabled: false,
    label: '',
    reverseDirection: false,
  },
)

const emit = defineEmits<{
  (name: 'input', value: boolean, event: Event): void
}>()

const disabledTextClasses = computed((): Record<string, boolean> => {
  return {
    'text-dark-text-400': props.darkMode === false,
    'text-light-text-400': props.darkMode === true,
    'text-dark-text-400 dark:text-light-text-400': props.darkMode === 'auto',
  }
})

const disabledSvgClasses = computed((): Record<string, boolean> => {
  return {
    'text-modal-overlay-grape': props.darkMode === false,
    'text-modal-overlay-canary': props.darkMode === true,
    'text-modal-overlay-grape dark:text-modal-overlay-canary': props.darkMode === 'auto',
  }
})

const isIOS = device.ios
const isMacOSSafari = device.mac && device.safari

const viewBoxForBrowsersOnAppleDevice = computed((): string =>
  props.dir === 'rtl' ? '-0.32 0.3 16 16' : '0.32 0.3 16 16',
)

const viewBox = computed((): string => (isIOS || isMacOSSafari ? viewBoxForBrowsersOnAppleDevice.value : '0 0 16 16'))

const onChange = (e: Event): void => {
  if (props.disabled) return
  emit('input', props.value, e)
}
</script>

<script lang="ts">
export default {}
</script>

<template>
  <div
    role="checkbox"
    :aria-checked="String(value)"
    :aria-disabled="disabled"
    tabindex="0"
    :class="[
      'flex gap-0.5',
      'items-center',
      disabled ? 'cursor-not-allowed' : 'cursor-pointer',
      'group',
      'outline-none',
      props.reverseDirection && 'flex-row-reverse',
    ]"
    @click="onChange"
    @keypress.stop.space="onChange"
    @keypress.stop.enter="onChange"
  >
    <!--
      Use border here instead of TW's focus ring functionality
      because ring make the checkbox rounder because it uses box-shadow under the hood
    -->
    <span
      :class="[
        'transition-colors',
        'w-[21px] h-[21px] my-[-0.5px] rounded box-border',
        'relative flex items-center justify-center',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        // Border
        'group-focus-visible:border-2 group-focus-visible:border-solid',
        {
          'border-grape-500': darkMode === false,
          'border-canary-500': darkMode === true,
          'border-grape-500 dark:border-canary-500': darkMode === 'auto',
        },
      ]"
    >
      <!--
        Use SVG icon here instead of OzIcon because the checkbox icon is inside a rectangle and it isn't at the center of the rectangle,
        so we cannot align the checkbox icon at the center of the container
        Moreover, we can use `transition-colors` with SVG icon.
      -->

      <!-- checkbox_outline_unchecked icon -->
      <svg
        v-if="!value"
        width="16"
        height="16"
        :viewBox="viewBox"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        :class="[
          !disabled && {
            'text-dark-text-300': darkMode === false,
            'text-light-text-300': darkMode === true,
            'text-dark-text-300 dark:text-light-text-300': darkMode === 'auto',
          },
          disabled && disabledTextClasses,
        ]"
      >
        <path
          d="M3 0.5C1.62915 0.5 0.5 1.62915 0.5 3V13C0.5 14.3708 1.62915 15.5 3 15.5H13C14.3708 15.5 15.5 14.3708 15.5 13V3C15.5 1.62915 14.3708 0.5 13 0.5H3ZM3 2.16667H13C13.47 2.16667 13.8333 2.53001 13.8333 3V13C13.8333 13.47 13.47 13.8333 13 13.8333H3C2.53001 13.8333 2.16667 13.47 2.16667 13V3C2.16667 2.53001 2.53001 2.16667 3 2.16667Z"
          fill="currentColor"
        />
      </svg>

      <!-- checkbox_filled_check icon -->
      <svg
        v-else
        width="16"
        height="16"
        :viewBox="viewBox"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        :class="[
          'transition-colors',
          !disabled && [
            {
              'text-grape-500': darkMode === false,
              'text-canary-500': darkMode === true,
              'text-grape-500 dark:text-canary-500': darkMode === 'auto',
            },
            {
              // We use `grape-500` with a 90% opacity.
              'hover-hover:group-hover:text-[rgba(148,102,232,0.9)]': darkMode === false,
              'hover-hover:group-hover:text-canary-600': darkMode === true,
              'hover-hover:group-hover:text-[rgba(148,102,232,0.9)] hover-hover:dark:group-hover:text-canary-600':
                darkMode === 'auto',
            },
          ],
          disabled && disabledSvgClasses,
        ]"
      >
        <path
          d="M13.2083 0.5H2.79167C1.52833 0.5 0.5 1.52833 0.5 2.79167V13.2083C0.5 14.4717 1.52833 15.5 2.79167 15.5H13.2083C14.4717 15.5 15.5 14.4717 15.5 13.2083V2.79167C15.5 1.52833 14.4717 0.5 13.2083 0.5ZM12.8171 5.73375L6.98375 11.5671C6.86167 11.6892 6.70167 11.75 6.54167 11.75C6.38167 11.75 6.22167 11.6892 6.09958 11.5671L3.59958 9.06708C3.35542 8.82292 3.35542 8.4275 3.59958 8.18333C3.84375 7.93917 4.23917 7.93917 4.48333 8.18333L6.54125 10.2413L11.9325 4.85C12.1767 4.60583 12.5721 4.60583 12.8162 4.85C13.0604 5.09417 13.0608 5.48958 12.8171 5.73375Z"
          fill="currentColor"
        />
      </svg>
    </span>

    <span
      v-if="label"
      :class="[
        'font-sans text-body-extra-small select-none',
        !disabled && [
          value
            ? {
                'text-dark-text-100': darkMode === false,
                'text-light-text-100': darkMode === true,
                'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
              }
            : [
                // Base
                {
                  'text-dark-text-200': darkMode === false,
                  'text-light-text-200': darkMode === true,
                  'text-dark-text-200 dark:text-light-text-200': darkMode === 'auto',
                },
                // Hover
                {
                  'hover-hover:group-hover:text-dark-text-100': darkMode === false,
                  'hover-hover:group-hover:text-light-text-100': darkMode === true,
                  'hover-hover:group-hover:text-dark-text-100 hover-hover:dark:group-hover:text-light-text-100':
                    darkMode === 'auto',
                },
              ],
        ],
        disabled && disabledTextClasses,
      ]"
      >{{ label }}</span
    >
  </div>
</template>
